<template>
  <div class="gap-2 grid grid-cols-3 mb-2 md:grid-cols-3">
    <div class="flex flex-col justify-center font-bold overflow-hidden">{{ props.header }}</div>
    <div
      class="col-span-2 whitespace-pre-line"
      :class="{
        'select-all': canCopy
      }"
    >
      <Skeleton v-if="loading && !data" width="15rem" />

      <slot v-if="data" name="body" :data="data">
        {{ value }}

        <Button
          v-if="props.canCopy"
          icon="pi pi-copy"
          variant="link"
          @click="() => copyToClipBoard(value)"
        />
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  field?: string
  header?: string
  canCopy?: boolean
}>(), {
  canCopy: false
})

const data = inject<any>('data')
const loading = inject<boolean>('loading')
const toast = useToast()

const value = computed(() => {
  if (!data.value) return
  if (!props.field) return
  return data.value[props.field]
})

const copyToClipBoard = async (text: any) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.add({
      detail: 'Copied to clipboard',
      life: 3000,
      severity: 'success',
      summary: 'Copied',
    })
  } catch (err) {
    toast.add({
      detail: err,
      life: 3000,
      severity: 'error',
      summary: 'Error',
    })
  }
}
</script>
